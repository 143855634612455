export const ROOT = '/'
export const LOGIN = '/login'
export const SUCURSALES = '/sucursales'
export const SELECCIONAR_COMUNA = '/comuna/seleccionar'
export const PROMOS = '/promos'
export const MENU = '/menu'
export const SHOPPING_CART = '/shopping/cart'
export const CHECKOUT = '/shopping/checkout'
export const PEDIDO = '/pedido'
export const MENU_QR = '/public/menuqr/:id'
export const ERROR = '/error'
export const PEDIDIO_COMPROBANTE = '/pedido/:uuid/comprobante'
export const PERFIL = '/perfil'