import React, { Component } from "react";
import { connect } from "react-redux";
import { Sucursal } from "../components/Sucursal";
import { resetScroll } from "../utils";


class Sucursales extends Component {
  componentDidMount() {
    resetScroll()
  }
  
  render() {
    return (
      <div>
        <section className="page-title" style={{backgroundImage: 'url(/images/background/contacto_sup.png)'}}>
          <div className="auto-container">
            <h3>Contactanos</h3>
            <h2>Nuestros Locales</h2>
          </div>
        </section>
        <section className="news-section">
          <div className="auto-container">
            <div className="row clearfix">
              {this.props.sucursales.map(sucursal => <Sucursal key={sucursal.id} { ...sucursal } />)}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = ({ sucursales }) => ({ sucursales })

export default connect(mapStateToProps)(Sucursales)