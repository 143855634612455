import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { SUCURSALES, ROOT, MENU, PROMOS, LOGIN, SHOPPING_CART, PEDIDO, PERFIL } from "../utils/Routes";
import { headerStyle, headerScrolling, hiddenBarMenuConfig } from "../Init";
import { destruir_session, google_session } from "../actions";
import { bindActionCreators } from 'redux';
import { autorizado_from_state, carrito_from_state, existe_pedido, is_session_google, persona_from_state } from '../reducers';
import { LogoutIcon } from './Icons';
import { GLogout, HiddenG } from './GoogleButtons';


class Header extends Component {
  componentDidMount() {
    headerStyle()
    headerScrolling()
    hiddenBarMenuConfig()
  }

  render() {
    const { cantidad, persona, autorizado, existe_pedido, session_google } = this.props
    const pathname = this.props.history.location.pathname

    return (
      <div>
        <header className="main-header header-style-two">
          <div className="main-box">
            <div className="auto-container">
              <div className="outer-container clearfix">
                <div className="logo-box">
                  <div className="logo">
                    <Link to={ROOT}><img src="images/logo.png" alt="Artica" /></Link>
                    {session_google && <HiddenG onFailure={this.props.google_session} onSuccess={this.props.google_session} />}
                  </div>
                </div>
                <div className="nav-outer clearfix">
                  <nav className="main-menu">
                    <div className="navbar-header">
                      <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div className="navbar-collapse collapse clearfix">
                      <ul className="navigation clearfix">
                        <MenuItem to={ROOT} current={pathname} label="Home"  />
                        {/*<li><a href="#quienessomos">Quienes Somos</a></li>*/}
                        <MenuItem to={MENU} current={pathname} label="Menu" />
                        <MenuItem to={PROMOS} current={pathname} label="Promos" />
                        <MenuItem to={SUCURSALES} current={pathname} label="Contacto" />
                        <CartMenuIcon cantidad={cantidad} existe_pedido={existe_pedido} />
                        {autorizado && <PerfilButton nombre={persona.nombre} />}
                        {autorizado && <SalirButton onClick={this.props.destruir_session} google={this.props.session_google} />}
                      </ul>
                    </div>
                  </nav>
                  {!autorizado && <IngresarButton path={pathname} onClick={this.props.destruir_session} />}
                </div>
                <div className="nav-toggler">
                  <button className="hidden-bar-opener"><span className="icon fa fa-bars"></span></button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="hidden-bar right-align">
          <div className="hidden-bar-closer">
            <button className="btn"><i className="fa fa-close"></i></button>
          </div>
          <div className="hidden-bar-wrapper">
            <div className="logo text-center">
              <Link to={ROOT}><img src="images/logo.png" alt="Kamisushi" /></Link>
            </div>
            <div className="side-menu">
              <ul className="navigation clearfix">
                <MenuItem to={ROOT} current={pathname} label="Home" onClick={toggle} />
                {/*<li><a href="#">Quienes Somos</a></li>*/}
                <MenuItem to={MENU} current={pathname} label="Menu" onClick={toggle} />
                <MenuItem to={PROMOS} current={pathname} label="Promos" onClick={toggle} />
                <MenuItem to={SUCURSALES} current={pathname} label="Contacto" onClick={toggle} />
                <CartMenuIcon cantidad={cantidad} existe_pedido={existe_pedido} />
                {!autorizado && <MenuItem to={LOGIN} current={pathname} label="Ingresar" className="reserv-box-btn" onClick={toggle} />}
                {autorizado && <PerfilButton nombre={persona.nombre} />}
                {autorizado && <SalirButton onClick={this.props.destruir_session} google={this.props.session_google} />}
              </ul>
            </div>
            <div className="social-icons">
              <ul>
                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const CartMenuIcon = ({ cantidad, existe_pedido, label }) => {
  return (
    <li className="cart-btn">
      <Link to={existe_pedido ? PEDIDO : SHOPPING_CART}>
        <span className="fa fa-shopping-cart"></span>
        {cantidad > 0 && <span className="cs-count">{cantidad}</span>}
        <span> Carrito</span>
      </Link>
    </li>
  )
}

const IngresarButton = ({ path }) => (
  <div className="btn-outer">
    <MenuItem to={LOGIN} current={path} label="Ingresar" className="reserv-box-btn" />
  </div>
)

const PerfilButton = ({ nombre }) => (
  <li>
    {/*<Link to={PERFIL}>{nombre}</Link>*/}
    <a>{nombre}</a>
  </li>
)

const SalirButton = ({ onClick, google }) => (
  <li>
    {google ? <GLogout logout={onClick} /> :
     <a onClick={() => onClick()}>Salir <LogoutIcon /></a>}
  </li>
)


const MenuItem = ({ to, label, current, className, onClick }) => (
  <li className={(className ? className : '') + (current === to && "current")}>
    <Link to={to} onClick={onClick}>{label}</Link>
  </li>
)

function toggle() {
  window.$('.hidden-bar').removeClass('visible-sidebar')
}

const mapStateToProps = (state) => ({
  cantidad: carrito_from_state(state).reduce((t, v) => t + v.cantidad, 0),
  persona: persona_from_state(state),
  autorizado: autorizado_from_state(state),
  existe_pedido: existe_pedido(state),
  session_google: is_session_google(state)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  destruir_session, google_session
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Header)