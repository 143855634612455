import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { clp } from '../utils/Numeral'
import { carrito_agregar } from "../actions";
import { fecha, is_empty, resetScroll } from '../utils';
import { productos_from_state, sucursal_from_state, persona_from_state } from '../reducers';
import { fetchHistorial, fetchTransacciones, isSuccess } from "../api";
import Spinner from '../components/Spinner';



class Perfil extends Component {
  state = {
    transacciones: [],
    loading: true
  }

  async componentDidMount() {
    try {
      const r = await fetchHistorial()
      console.log('historial', r)
      const res = await fetchTransacciones()
      if (isSuccess(res)) {
        this.setState({ transacciones: res.data })
      }
    } catch (e) {
      console.log('[fetchTransacciones]', e)
    } finally {
      this.setState({ loading: false })
    }
  }

  render()  {
    const { email, nombre, apellido } = this.props.persona
    const transacciones = this.state.transacciones

    return (
      <div>
        <section className="news-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-md-9 col-md-offset-1">
                <div className="panel panel-default">
                  <div className="panel-body">
                    <div className="sec-title-one">
                      <h2>Kamisushi</h2>
                      <h4>Transacciones Webpay</h4>
                    </div>
                    <div className="content">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Número de orden</th>
                            <th>Número de sesion</th>
                            <th>Código de autorización</th>
                            <th>Fecha y Hora</th>
                            <th>Monto</th>
                            <th>Estado</th>
                            <th>Codigo del Pedido</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transacciones.map(t => (
                            <tr key={t.uuid}>
                              <td>{t.buy_order}</td>
                              <td>{t.session_id}</td>
                              <td>{t.authorization_code}</td>
                              <td>{fecha(t.momento)}</td>
                              <td>${clp(t.monto)}</td>
                              <td>{t.estado}</td>
                              <td>{t.uuid}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {this.state.loading && <div style={{textAlign: 'center'}}><Spinner /></div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    persona: persona_from_state(state),
    sucursal: sucursal_from_state(state)
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({ carrito_agregar }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Perfil)