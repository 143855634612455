import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

import CartItem from '../components/CartItem'
import { autorizado_from_state, comuna_from_state } from '../reducers'
import { DESPACHO, EFECTIVO, PRIMARY_COLOR, RETIRO } from '../utils/Constants'
import { LOGIN, ROOT } from '../utils/Routes'
import { anular_pedido, obtener_pedido } from "../actions";
import { reduceProductos, resetScroll } from '../utils'
import { SucursalHorizontal } from '../components/Sucursal'
import { clp } from '../utils/Numeral'
import { resolveMedioPago, TARJETA } from '../utils/MediosPago'
import { DELIVER, resolveStatus, statusProgress } from '../utils/Status'
import Spinner from '../components/Spinner';



function sortProducto(a, b) {
  return a.index - b.index
}

class Pedido extends Component {
  state = {
    loading: false
  }

  componentDidMount() {
    resetScroll()
    this.refresh()
  }

  refresh = async () => {
    const res = await this.props.obtener_pedido()
    if (res && res.data !== null) {
      setTimeout(this.refresh, 30000)
    }
  }

  anular = async () => {
    try {
      this.toggle()
      await this.props.anular_pedido()
    } catch (e) {
      console.error(e)
      this.toggle()
    }
  }

  toggle = () => this.setState({ loading: !this.state.loading })

  render() {
    const { sucursales, autorizado, pedido } = this.props
    const { loading } = this.state

    if (!autorizado) {
      return <Redirect to={LOGIN} />
    }

    const { _id, products, user, sucursal, type, deliveryCost, payment, status, comment, uuid } = pedido

    if (!_id || _id === null) {
      return <Redirect to={ROOT} />
    }

    const _sucursal = sucursales.find(v => v.id === sucursal)
    const despacho = type === DESPACHO
    const total_productos = products.reduce(reduceProductos, 0)
    const total = total_productos + (despacho ? deliveryCost : 0)
    const disabled = loading || status === DELIVER || status === RETIRO

    return (
      <div className="checkout-page">
        <div className="auto-container">
          <section className="cart-section">
            <div className="sec-title-one">
              <h2>Kamisushi</h2>
            </div>
            <div style={{ width: '100%', textAlign: 'center'}}>
              <label>{resolveStatus(status)}</label>
              <Progress percent={statusProgress(status)} />
            </div>
            <div className="cart-options clearfix">
              <div className="billing-details">
                <div className="shop-form default-form">
                    <div className="row clearfix">
                      <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <div className="default-title"><h2>Número de pedido: {pedido.index}</h2></div>
                        <div className="default-title">Código de pedido: {pedido.uuid}</div>
                        <div className="row clearfix">
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>Nombre</label>
                            <input type="text" value={user.name} disabled />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>Telefono</label>
                            <input type="text" value={user.phone} disabled />
                          </div>
                          {despacho && <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label>Direccion</label>
                            <input type="text" value={user.address} disabled/>
                          </div>}
                          {!despacho &&
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <input type="text" value="Retiro en local" disabled />
                          </div>}
                          <div className="form-group">
                          <textarea value={comment} disabled />
                          </div>
                        </div>
                      </div>
                    </div>                             
                </div>
              </div>
              <h4>{despacho ? "Has seleccionado despacho a domicilio": "Tu sucursal de retiro"}</h4>
              {_sucursal && <SucursalHorizontal {..._sucursal} />}
              <div className="mt-3 clearfix cart-options">
                {products.map(fix_product).sort(sortProducto).map((v, index) => (
                  <CartItem key={v._id} {...v}
                    underline={index !== products.length - 1} />
                ))}
              </div>
              <div className="column pull-right col-md-8 col-sm-12 col-xs-12">
                <div className="price-calculator">
                  <div className="outer-box">
                    <div className="top-bar">
                      <ul className="totals-table">
                        <li className="clearfix"><span className="col bold-text">Medio de pago</span><span className="col total-amount">{resolveMedioPago(payment)}</span></li>
                      </ul>
                      <div className="default-title"><h3>Total del carrito</h3></div>
                      <ul className="totals-table">
                        <li className="clearfix"><span className="col bold-text">Subtotal</span><span className="col total-amount">${clp(total_productos)}</span></li>
                        {despacho && <li className="clearfix"><span className="col bold-text">Despacho</span><span className="col total-amount">${clp(deliveryCost)}</span></li>}
                      </ul>
                      <ul className="lower-total">
                        <li className="clearfix"><span className="col bold-text">TOTAL</span><span className="col total">${clp(total)}</span></li>
                      </ul>
                    </div>
                  </div>
                  {/*payment !== TARJETA &&*/ <div className="check-btns">
                    <button onClick={this.anular} type="button" className="theme-btn btn-style-three proceed-btn" disabled={disabled}>
                      {loading ? <Spinner /> : 'Anular Pedido'}
                    </button>
                  </div>}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

function fix_product(v) {
  return { ...v,  cantidad: v.quantity }
}


const mapStateToProps = (state) => ({
  pedido: state.pedido,
  autorizado: autorizado_from_state(state),
  comuna: comuna_from_state(state),
  sucursales: state.sucursales,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ 
  anular_pedido,
  obtener_pedido
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Pedido)