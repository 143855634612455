import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fijar_comuna_activa } from "../actions";
import { int, is_empty, resetScroll } from '../utils';
import { MENU } from "../utils/Routes";


const ConfigurarComuna = ({ id_comuna, comunas, fijar_comuna, history }) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => resetScroll())

  const _onChange = async (e) => {
    setLoading(true)
    try {
      await fijar_comuna(int(e.target.value))
      console.log('fijada')
    } finally {
      setLoading(false)
    }
  }

  const goBack = () => {
    history.push(MENU)
  }

  const disabled = is_empty(id_comuna) || loading

  return (
    <section className="news-section">
      <div className="auto-container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="sec-title-one">
                  <h2>Kamisushi</h2>
                  <h4>Selecciona tu sector</h4>
                </div>
                <div className="form-box default-form wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <form onSubmit={goBack}>
                    <div className="form-group group-inner">
                      <select value={id_comuna} style={{ backgroundColor: 'white'}} onChange={_onChange} disabled={loading}>
                        <option value="">----------------</option>
                        {comunas.map(v => (
                          <option key={v.id} value={v.id}>{v.nombre}</option>
                        ))}
                      </select>
                    </div>
                    <button className="theme-btn btn-style-three proceed-btn btn-block" disabled={disabled}>Continuar</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function sortComunas(a, b) {
  return a.nombre > b.nombre ? 1 : -1
}

const mapStateToProps = ({ comunas, session }) => ({
  id_comuna: session.id_comuna,
  comunas: comunas.sort(sortComunas)
})

const mapDispatachToProps = (dispatch)  => bindActionCreators({ 
  fijar_comuna: fijar_comuna_activa
}, dispatch)

export default connect(mapStateToProps, mapDispatachToProps)(ConfigurarComuna)