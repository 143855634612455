import React from "react";

const Sucursal = ({ id, nombre, thumbnail, direccion, coordenadas, telefonos }) => {
  const fijos = telefonos.filter(v => v.indexOf('+56') === -1)
  const mobil = telefonos.filter(v => v.indexOf('+56') !== -1)

  return (
    <div key={id} className="news-block col-md-4 col-sm-6 col-xs-12">
      <h2 className="text-center">{nombre}</h2>
      <div className="inner-box">
        <figure className="image-box">
          <img style={{ height: 260, width: 370 }} src={thumbnail !== null ? '/images/gallery/' + thumbnail : "/images/resource/branch-1.jpg"} alt="" />
        </figure>
        <div className="lower-content">
          <ul className="list-style-two">
            <li><span className="icon flaticon-arrows-6"></span> <GMapLink {...coordenadas} label={direccion} /></li>
            <li><span className="fa fa-whatsapp"></span> {mobil.map(v => <Telefono key={v} telefono={v} />)}</li>
            <li><span className="icon flaticon-technology"></span> {fijos.join(', ')}</li>
            <li><span className="icon flaticon-empty-email"></span><a href="mailto:contacto@franquiciaskamisushi.cl"> contacto@franquiciaskamisushi.cl</a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const SucursalHorizontal = ({ nombre, thumbnail, direccion, telefonos = [], coordenadas }) => {
  const fijos = telefonos.filter(v => v.indexOf('+56') === -1)
  const mobil = telefonos.filter(v => v.indexOf('+56') !== -1)

  return (
    <div className="place-order">
      <div className="default-title"><h2>Sucursal {nombre}</h2></div>
      <div className="row">
        <figure className="image-box col-md-3">
          <img src={thumbnail !== null ? '/images/gallery/' + thumbnail : "/images/resource/branch-1.jpg"} alt="" />
        </figure>
        <ul className="list-style-two col-md-9">
          <li><span className="icon flaticon-arrows-6"></span><GMapLink {...coordenadas} label={direccion} /></li>
          <li><span className="fa fa-whatsapp"></span> {mobil.map(v => <Telefono key={v} telefono={v} />)}</li>
          <li><span className="icon flaticon-technology"></span> {fijos.join(', ')}</li>
          <li><span className="icon flaticon-empty-email"></span><a href="mailto:contacto@franquiciaskamisushi.cl"> contacto@franquiciaskamisushi.cl</a></li>
        </ul>
      </div>
    </div>
  )
}

const GMapLink = ({ latitude, longitude, label }) => (
  <a href={`http://www.google.com/maps/place/${latitude},${longitude}`} target="_blank">{label}</a>
)

const Telefono = ({ telefono }) => {
  const value = telefono.trim().replace(/ /g, '')
  return <a href={`https://wa.me/${value}`} target="_blank">{telefono}</a>
}

export {
  Sucursal, SucursalHorizontal
}