import React from 'react'
import { clp } from '../utils/Numeral'

const CartItem = ({ underline, _id, cantidad, title, price, asset, carrito_agregar, carrito_eliminar, carrito_quitar }) => {
  const plus = () => carrito_agregar(_id)
  const minus = () => carrito_quitar(_id)
  const remove = () => carrito_eliminar(_id)

  return (
    <div className={underline ? "underline" : ""}>
      <div className="row">
        <div className="col-xs-4 col-md-2">
          <figure className="prod-thumb"><img src={"images/gallery/" + asset} alt="" /></figure>
        </div>
        <div className={carrito_eliminar ? "col-xs-6" : "col-xs-7"}>
          <h4 className="prod-title">{title}</h4>
          <div className="sub-total">${clp(price)}</div>
          <div className="qty">
            <div className="quantity-spinner">
              {carrito_eliminar && <button type="button" className="minus"  onClick={minus}><span className="fa fa-minus"></span></button>}
              <input type="text" name="product" value={cantidad} className="prod_qty" disabled />
              {carrito_agregar && <button type="button" className="plus" onClick={plus}><span className="fa fa-plus"></span></button>}
            </div>
          </div>  
          <div className="mt-1"><h4>Total ${clp(cantidad * price)}</h4></div>
        </div>
        {carrito_eliminar && <div className="col-xs-2">
          <a onClick={remove} className="remove-btn"><span className="fa fa-remove"></span></a>
        </div>}
      </div>
    </div>
  )
}


export default CartItem