export const EFECTIVO = 0
export const TARJETA = 1

const MediosPago = [{
  id: EFECTIVO,
  label: 'Efectivo'
}, {
  id: TARJETA,
  label: 'Webpay'
}]

export function resolveMedioPago(id) {
  const value = MediosPago.find(mp => mp.id === id)
  return value ? value.label : ''
}

export default MediosPago