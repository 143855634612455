import React, { Component } from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from './components/Header'
import Footer from './components/Footer'
import { Home, Sucursales, Menu, Promos, Login, ShoppingCart, Checkout, ConfigurarComuna, Pedido, Comprobante, Error, Perfil } from "./screens"
import { SUCURSALES, ROOT, MENU, PROMOS, LOGIN, SHOPPING_CART, CHECKOUT, SELECCIONAR_COMUNA, MENU_QR, PEDIDO, PEDIDIO_COMPROBANTE, ERROR, PERFIL } from "./utils/Routes";
import { handlePreloader } from "./Init";
import { initial_setup } from "./actions";
import MenuQR from './screens/MenuQR';

import './App.css'


class App extends Component {
  componentDidMount() {
    handlePreloader()
    this.props.initial_setup()
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path={MENU_QR} component={MenuQR} />
          <Route render={props => <Main {...props} comuna={this.props.comuna} />} />
        </Switch>
      </Router>
    )
  }
}

const Main = ({ comuna }) => (
  <div className="page-wrapper">
    <div className="preloader"></div>
    {!comuna && <Redirect to={SELECCIONAR_COMUNA} />}
    <Route component={Header} />
    <Switch>
      <Route exact path={ROOT} component={Home} />
      <Route path={SUCURSALES} component={Sucursales} />
      <Route path={MENU} component={Menu} />
      <Route path={PROMOS} component={Promos} />
      <Route path={LOGIN} component={Login} />
      <Route path={SHOPPING_CART} component={ShoppingCart} />
      <Route path={CHECKOUT} component={Checkout} />
      <Route path={SELECCIONAR_COMUNA} component={ConfigurarComuna} />
      <Route exact path={PEDIDO} component={Pedido} />
      <Route path={PEDIDIO_COMPROBANTE} component={Comprobante} />
      <Route path={ERROR} component={Error} />
      <Route path={PERFIL} component={Perfil} />
    </Switch>
    <Route component={Footer} />
  </div>
)

const mapStateToProps = ({ session }) => ({
  comuna: session.id_comuna !== null
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  initial_setup
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(App)