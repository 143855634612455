import { del, get, post, put } from "./Methods";


export const fetchComunas = () => get('/comunas') 
export const fetchSucursales = () => get('/sucursales')
export const fetchProductos = (sucursal) => get('/api/v1/productos/' + sucursal)
export const fetchCategorias = (sucursal) => get(`/api/v1/sucursales/${sucursal}/categorias`)

export const login = (email, codigo) => post('/api/nori/login', { email, codigo })
export const logout = () => del('/api/v1/logout')
export const getSession = () => get('/api/v1/session')
export const enviarCorreo = (email) => post('/api/email', { email })
export const crearUsuario = (usuario) => post('/api/v1/nori/usuario', usuario)

export const fetchDirecciones = () => get('/api/v1/nori/usuario/direcciones')
export const agregarDireccion = (direccion) => put('/api/v1/nori/usuario/direcciones', direccion)
export const quitarDireccion = (id) => del('/api/v1/nori/usuario/direcciones/' + id)
export const actualizarUsuario = (usuario) => put('/api/v1/nori/user', usuario)
export const costoDespacho = (body) => post('/api/v1/sucursales/despacho', body)

export const ingresarPedido = (body) => put('/api/v1/nori/pedido', body)
export const anularPedido = (id) => post('/api/v1/nori/pedido', { id })
export const obtenerPedido = () => get('/api/v1/nori/pedido')

export const pedidoWebpay = (body) => post('/api/v1/transbank/transaccion/crear', body)
export const estadoPedidoWebpay = (uuid) => get('/api/v1/transbank/transaccion/' + uuid)
export const fetchTransacciones = () => get('/api/v1/transbank/transaccion')
export const fetchHistorial = () => get('/api/v1/nori/usuario/historial')


export function isSuccess(res) {
  return res && res.status === 'success'
}