import React, {  Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { crear_session, google_auth } from "../actions";
import { autorizado_from_state } from '../reducers';


class Error extends Component {
  render() {
    return (
      <div>
        <section className="news-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="panel panel-default">
                  <div className="panel-body">
                    <div className="sec-title-one">
                      <h2>Kamisushi</h2>
                      <h4>Orden de Compra rechazada</h4>
                    </div>
                    <div className="content">
                      <div className="text">
                        <p>Las posibles causas de este rechazo son:</p>
                        <ul>
                          <li>* Error en el ingreso de los datos de su tarjeta de crédito o débito (fecha y/o código de seguridad).</li>
                          <li>* Su tarjeta de crédito o débito no cuenta con saldo suficiente.</li>
                          <li>* Tarjeta aún no habilitada en el sistema financiero.</li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  autorizado: autorizado_from_state(state)
})

const mapDispatachToProps = (dispatch)  => bindActionCreators({
  crear_session,
  google_auth,
}, dispatch)

export default connect(mapStateToProps, mapDispatachToProps)(Error)