export const WORKING = 'WORKING'
export const DONE = 'DONE'
export const DELIVER = 'DELIVER'
export const RETIRO = 'RETIRO'
export const COMPLETED = 'COMPLETED'
export const CANCELED = 'CANCELED'


export function resolveStatus(status) {
  switch (status) {
    case DELIVER: return 'Tu pedido esta en camino'
    case RETIRO: return 'Tu pedido esta listo para retirar'
    case COMPLETED: return 'Pedido Completado'
    case CANCELED: return 'Pedido Anulado'
    default: return  'Estamos preparando tu pedido'
  }
}

export function statusProgress(status) {
  switch (status) {
    case WORKING: return 30
    case DONE: return 50
    case DELIVER: return 70
    case RETIRO: return 70
    case COMPLETED: return 100
    case CANCELED: return 100
    default: return 30
  }
}