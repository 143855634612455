const DEV_GOOGLE_API_KEY = 'AIzaSyAJtgPu4Q3rNkFMu1Yw9zuy-TVOirVI8sY'//'AIzaSyDR6QE6j2CUyUWani79BXpe3x_OM0SLGQw'
const LOCAL_HOST = 'http://localhost:8000'
const PRODUCTION_HOST = 'https://api.clan28.cl'

const production = process.env.NODE_ENV === 'production'

const googleKey = DEV_GOOGLE_API_KEY
const host = production ? PRODUCTION_HOST : LOCAL_HOST
//const host = PRODUCTION_HOST

export {
  googleKey, host
}