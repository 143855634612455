import React from "react";
import { GoogleLogout, GoogleLogin } from 'react-google-login';
import { LogoutIcon } from "./Icons";

const CLIENT_ID = "231222946548-7c2sp6jchdogdss7l2r1r55jeo8umk00.apps.googleusercontent.com"

const GLogin = ({ onSuccess, onFailure }) => (
  <GoogleLogin
    className="btn-block"
    clientId={CLIENT_ID}
    buttonText="Ingresa con tu cuenta google"
    onSuccess={onSuccess}
    onFailure={onFailure}
    cookiePolicy={'single_host_origin'}
  />
)

const HiddenG = ({ onSuccess, onFailure }) => (
  <GoogleLogin
    className="btn-block"
    clientId={CLIENT_ID}
    render={() => null}
    onSuccess={onSuccess}
    onFailure={onFailure}
    cookiePolicy={'single_host_origin'}
    isSignedIn={true}
  />
)

const GLogout = ({ logout }) => (
  <GoogleLogout
    clientId={CLIENT_ID}
    render={renderProps => (
      <a onClick={renderProps.onClick}>Salir <LogoutIcon /></a>
    )}
    onLogoutSuccess={logout}
  /> 
)

export {
  GLogin, GLogout, HiddenG
}