import { combineReducers } from "redux";
import { CARRITO_ADD, CARRITO_DELETE, CARRITO_REMOVE, ELIMINAR_SESSION,
   FIJAR_COMUNAS, FIJAR_COMUNA_ACTIVA, FIJAR_DIRECCIONES, FIJAR_G_SESSION, FIJAR_PEDIDO, FIJAR_PRODUCTOS, FIJAR_SESSION,
   FIJAR_SUCURSALES, 
   FIJAR_USUARIO,
   QUITAR_PEDIDO} from "../actions/Types";
import { is_empty } from "../utils";
import createReducer from "./createReducer";


export function autorizado_from_state({ session }) {
  return !is_empty(session.token)
}

export function comuna_from_state({ comunas, session }) {
  return comunas.find(v => v.id === session.id_comuna)
}

export function sucursal_from_state({ sucursales, session }) {
  return sucursales.find(s => s.comunas.some(v => v === session.id_comuna))
}

export function productos_from_state({ sucursales, session, productos }) {
  const sucursal = sucursal_from_state({ sucursales, session })
  return sucursal ? productos.filter(v => v.sucursal === sucursal.id) : []
}

export function carrito_from_state(state) {
  const productos = productos_from_state(state)

  return state.carrito.filter(v => productos.some(k => v._id === k._id)).map(v => ({
    ...productos.find(k => k._id === v._id),
    cantidad: v.cantidad
  }))
}

export function persona_from_state({ session }) {
  const nombre = session.nombre !== null ? session.nombre : ''
  const telefono = session.telefono !== null ? session.telefono : ''

  return {
    email: session.email,
    id_persona: session.id_persona,
    nombre,
    telefono,
    direcciones: session.direcciones.filter(v => v.coordenadas !== null)//.filter(v => v.comuna === session.id_comuna)
  }
}

export function existe_pedido({ pedido }) {
  return pedido._id && pedido._id !== null
}

const NORI_TYPE = 'NORI_TYPE'
const GOOGLE_TYPE = 'GOOGLE_TYPE'

export function is_session_google({ session }) {
  return session.type === GOOGLE_TYPE
}


const initialSession = {
  type: NORI_TYPE,
  token: '',
  email: '',
  direcciones: [],
  direccion: null,
  id_comuna: 1,
  id_persona: null,
  nombre: '',
  telefono: '',
}

const session = createReducer(initialSession, {
  [FIJAR_SESSION]: (state, { token, email }) => ({
    ...state, token, email,
    type: NORI_TYPE
  }),
  [ELIMINAR_SESSION]: (state) => ({ ...initialSession, id_comuna: state.id_comuna }),
  [FIJAR_COMUNA_ACTIVA]: (state, { id_comuna }) => ({
    ...state,
    id_comuna
  }),
  [FIJAR_USUARIO]: (state, { usuario }) => ({
    ...state,
    nombre: usuario.nombre,
    telefono: usuario.telefono,
    id_persona: usuario.id
  }),
  [FIJAR_DIRECCIONES]: (state, { direcciones }) => ({
    ...state,
    direcciones
  }),
  [FIJAR_G_SESSION]: (state, { email, nombre, token }) => ({
    ...state,
    email,
    nombre,
    token,
    type: GOOGLE_TYPE
  })
})

const productos = createReducer([], {
  [FIJAR_PRODUCTOS]: (state, { productos }) => [ ...productos ],
  [FIJAR_COMUNA_ACTIVA]: () => []
})

const carrito = createReducer([], {
  [CARRITO_ADD]: (state, { _id }) => {
    const value = state.find(v => v._id === _id)
    if (!value) {
      return [ ...state, { _id, cantidad: 1 } ]
    }
    return [ ...state.filter(v => v._id !== _id), { _id, cantidad: value.cantidad + 1}]
  },
  [CARRITO_REMOVE]: (state, { _id })  => state.map(v => ({
    ...v,
    cantidad: v._id === _id ? v.cantidad - 1 : v.cantidad
  })).filter(v => v.cantidad > 0),
  [CARRITO_DELETE]: (state, { _id}) => state.filter(v => v._id !== _id),
  [ELIMINAR_SESSION]: () => [],
  [FIJAR_COMUNA_ACTIVA]: () => [],
  [FIJAR_PEDIDO]: () => []
})

const sucursales = createReducer([], {
  [FIJAR_SUCURSALES]: (state, { sucursales }) => [ ...sucursales ]
})

const comunas = createReducer([], {
  [FIJAR_COMUNAS]: (state, { comunas }) => [ ...comunas ]
})


const initialPedido = {
  _id: null
}

const pedido = createReducer(initialPedido, {
  [FIJAR_PEDIDO]: (state, { pedido }) => ({
    ...pedido
  }),
  [QUITAR_PEDIDO]: () => ({ ...initialPedido })
})

export default combineReducers({
  session, carrito, productos, sucursales, comunas, pedido
})