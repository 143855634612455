import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom';

import { clp } from '../utils/Numeral'
import { carrito_agregar } from "../actions";
import { is_empty, resetScroll } from '../utils';
import ComunaActual from '../components/ComunaActual';
import FloatingMessage from "../components/FloatingMessage";
import { productos_from_state, sucursal_from_state } from '../reducers';
import { SELECCIONAR_COMUNA, SHOPPING_CART } from '../utils/Routes';
import CartButton from '../components/CartButton';


class Promos extends Component {
  state = {
    alert: false
  }

  componentDidMount() {
    resetScroll()
  }

  componentWillUnmount() {
    if (this._timer) {
      clearTimeout(this._timer)
    }
  }

  render()  {
    const { productos, carrito_agregar, sucursal, history } = this.props
    const alert = this.state.alert

    if (!sucursal) {
      return <Redirect to={SELECCIONAR_COMUNA} />
    }

    const setAlert = (v) => this.setState({ alert: v })

    const add = (v) => {
      carrito_agregar(v)
      setAlert(true)
      this._timer = setTimeout(() => setAlert(false), 1500)
    }

    return (
      <div>
        <section className="parallax-image-section" style={{ backgroundImage: 'url(images/background/principal_promo.jpg)' }}>
          <div className="auto-container">
            <h2>Elije tu promoción</h2>
            <h4>Promociones</h4>
          </div>
        </section>
        <section className="shop-section">
          <div className="auto-container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CartButton onClick={() => history.push(SHOPPING_CART)} />
              <div style={{ flex: 1 }}>
                <ComunaActual />
              </div>
            </div>
            <div className="row clearfix mt-3">
              {productos.sort(porPrecio).map(v => <Promo key={v._id} {...v} agregar={add} />)}
            </div>
          </div>
        </section>
        <FloatingMessage visible={alert} />
      </div>
    )
  }
}

function porPrecio(a, b) {
  return a.price - b.price
}

const Promo = ({ _id, title, price, detail, asset, agregar }) => (
  <div key={_id} className="dish-item col-md-4 col-sm-6 col-xs-12">
    <div className="inner-box">
      <figure className="image-box">
        <a onClick={() => agregar(_id)}><img style={{width: 370, height: 420}} src={asset !== '' ? "images/gallery" + asset : 'images/resource/dish-1.jpg'} alt="" /></a>
      </figure>
      <div className="lower-content">
        <h3 className="clearfix"><a href="">{title.toUpperCase()} <span className="pull-right">${clp(price)}</span></a></h3>
        <div className="text">{is_empty(detail) ? '--' : detail}</div>
      </div>
    </div>
  </div>
)

const mapStateToProps = (state) => {
  return {
    sucursal: sucursal_from_state(state),
    productos: productos_from_state(state).filter(v => v.tags === 'Promociones')
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({ carrito_agregar }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Promos)