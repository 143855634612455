import React from "react";
import { is_empty } from "../utils";

const ErrorMessage = ({ message }) => !is_empty(message) && (
  <div className="alert alert-danger">
    {message}
  </div>
)

export {
  ErrorMessage
}