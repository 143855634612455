import React from 'react'

export const LoadingIcon = () => (
  <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw" aria-hidden="true"></i>
)

export const SettingIcon = () => (
  <i className="fa fa-cog fa-2x fa-fw" aria-hidden="true"></i>
)

export const UserIcon = () => (
  <i className="fa fa-user fa-2x fa-fw" aria-hidden="true"></i>
)

export const LogoutIcon = () => (
  <i className="fa fa-sign-out fa-2x fa-fw" style={{color: 'white'}} aria-hidden="true"></i>
)

export const ShoppingIcon = () => (
  <i className="fa fa-shopping-cart fa-2x fa-fw" aria-hidden="true"></i>
)