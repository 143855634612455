import { fetchComunas, fetchProductos, fetchSucursales, isSuccess, login, logout, getSession, ingresarPedido, anularPedido, fetchDirecciones, actualizarUsuario, agregarDireccion, obtenerPedido, crearUsuario, pedidoWebpay } from "../api";
import { autorizado_from_state } from "../reducers";
import { CARRITO_ADD, CARRITO_DELETE, CARRITO_REMOVE, ELIMINAR_SESSION,
  FIJAR_COMUNAS, FIJAR_USUARIO,
  FIJAR_COMUNA_ACTIVA, FIJAR_G_SESSION,
   FIJAR_PRODUCTOS, FIJAR_SESSION, FIJAR_SUCURSALES, FIJAR_ERROR, FIJAR_PEDIDO, QUITAR_PEDIDO, FIJAR_DIRECCIONES } from "./Types";


export const initial_setup = () => async (dispatch, getState) => {
  await fetchSucursales().then(fijar_sucursales).then(dispatch)
  await fetchComunas().then(fijar_comunas).then(dispatch)
  
  
  if (autorizado_from_state(getState())) {
    await obtener_sesion(dispatch)
    await obtener_pedido()(dispatch, getState)
  }

  return await fetch_productos(getState()).then(dispatch)
}

async function obtener_sesion(dispatch) {
  const res = await getSession()
  //console.log('sesion', res)
  noAutorizado(dispatch, res)
  if (isSuccess(res)) {
    dispatch(fijar_session(res))
    await fijar_direcciones(dispatch)
  }
}

async function fijar_direcciones(dispatch) {
  const dirRes = await fetchDirecciones()
  if (isSuccess(dirRes)) {
    dispatch({
      type: FIJAR_DIRECCIONES,
      direcciones: dirRes.data
    })
  }
}

async function fetch_productos({ session, sucursales }) {
  const id_comuna = session.id_comuna
  const sucursal = buscar_sucursal(sucursales, id_comuna)

  if (!sucursal) {
    return fijar_error('No hay comuna')
  }
  
  const res = await fetchProductos(sucursal.id)

  if (isSuccess(res)) {
    return {
      type: FIJAR_PRODUCTOS,
      productos: res.data
    }
  } else {
    return fijar_error(res.data)
  }
}

export function crear_session(email, codigo) {
  return async (dispatch) =>{
    const res = await login(email, codigo)
    if (isSuccess(res)) {
      dispatch({
        type: FIJAR_SESSION,
        email,
        token: res.data
      })
      await obtener_sesion(dispatch)
    }
    return res
  }
}

export function google_auth(res) {
  const authRes = res.getAuthResponse()
  const profile = res.getBasicProfile()
  const email = profile.getEmail()
  const nombre = profile.getName()

  return async (dispatch) => {
    const usuario = {
      email,
      nombre
    }
    
    const res2 = await crearUsuario(usuario)
    if (isSuccess(res2)) {
      dispatch(fijar_google_session(email, nombre, authRes))
      await obtener_sesion(dispatch)
      await fijar_direcciones(dispatch)
    } else {
      dispatch(fijar_error(res2.data))
    }
  }
}

function fijar_google_session(email, nombre, authRes) {
  return {
    type: FIJAR_G_SESSION,
    email,
    nombre,
    token: authRes.id_token
  }
}

export function google_session(res) {
  return (dispatch) => {
    if (res.error) {
      return dispatch(eliminar_session())
    }

    const authRes = res.getAuthResponse()
    const profile = res.getBasicProfile()
    const email = profile.getEmail()
    
    dispatch(fijar_google_session(email, profile.getName(), authRes))
    
    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse()
      if (!newAuthRes.tokenObj) {
        return dispatch(eliminar_session())
      }

      dispatch(fijar_google_session(email, profile.getName(), newAuthRes))
      setTimeout(refreshToken, refreshTime(newAuthRes))
    }

    setTimeout(refreshToken, refreshTime(res))
  }
}

function refreshTime(res) {
  return (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000
}

export function obtener_pedido() {
  return async (dispatch, getState) => {
    const autorizado = autorizado_from_state(getState())
    if (!autorizado) {
      return
    }
    const res = await obtenerPedido()
    noAutorizado(dispatch, res)
    if (isSuccess(res)) {
      dispatch({
        type: res.data === null ? QUITAR_PEDIDO : FIJAR_PEDIDO,
        pedido: res.data
      })
    }
    return res
  }
}

export function enviar_pedido(pedido, direccion) {
  return async (dispatch, getState) => {
    try {
      const { sucursales, session } = getState()
      const sucursal = buscar_sucursal(sucursales, session.id_comuna)
      const id_sucursal = sucursal.id
      const body = {
        ...pedido,
        products: pedido.products.map(v => ({ _id: v._id, quantity: v.quantity })),
        id_sucursal
      }
  
      const res = await ingresarPedido(body)
      noAutorizado(dispatch, res)
      if (isSuccess(res)) {
        await actualizar_usuario(dispatch, body, direccion, session, id_sucursal)
        dispatch({
          type: FIJAR_PEDIDO,
          pedido: res.data
        })
      } else {
        dispatch(fijar_error(res.data))
      }
      return res
    } catch (e) {
      fijar_error(e)
    }
  }
}

export function enviar_pedido_webpay(pedido, direccion) {
  return async (dispatch, getState) => {
    try {
      const { sucursales, session } = getState()
      const sucursal = buscar_sucursal(sucursales, session.id_comuna)
      const id_sucursal = sucursal.id
      const body = {
        pedido:  {
          ...pedido,
          products: pedido.products.map(v => ({ _id: v._id, quantity: v.quantity })),
        },
        id_sucursal
      }

      const res = await pedidoWebpay(body)
      noAutorizado(dispatch, res)
      if (isSuccess(res)) {
        await actualizar_usuario(dispatch, pedido, direccion, session, id_sucursal)
        dispatch({
          type: FIJAR_PEDIDO,
          pedido: res.data
        })
      } else {
        dispatch(fijar_error(res.data))
      }
      return res
    } catch (e) {
      fijar_error(e)
    }
  }
}

function noAutorizado(dispatch, res) {
  const no = res.data === 4001 || res.data === 'No autorizado'
  if (no) {
    dispatch(eliminar_session())
  }
  return no
}

async function actualizar_usuario(dispatch, pedido, direccion, { id_persona, id_comuna }, id_sucursal) {
  const usuario = {
    nombre: pedido.user.name,
    telefono: pedido.user.phone,
    sucursal: id_sucursal,
    id: id_persona
  }
  const res = await actualizarUsuario(usuario)
  noAutorizado(dispatch, res)
  if (isSuccess(res)) {
    direccion.persona = res.data
    direccion.comuna = id_comuna
    await agregarDireccion(direccion)
    await obtener_sesion(dispatch)
  }
}

export function anular_pedido() {
  return async (dispatch, getState) => {
    const { _id } = getState().pedido
    const res = await anularPedido(_id)

    noAutorizado(dispatch, res)
    if (isSuccess(res)) {
      dispatch({
        type: QUITAR_PEDIDO
      })
    }
  }
}

export function fijar_comuna_activa(id_comuna) {
  return (dispatch, getState) => {
    dispatch(fijar_comuna(id_comuna))
    return fetch_productos(getState()).then(dispatch)
  }
}

function fijar_comuna(id_comuna) {
  return {
    type: FIJAR_COMUNA_ACTIVA,
    id_comuna,
  }
}

export function destruir_session() {
  return (dispatch) => logout().finally(() => dispatch(eliminar_session()))
}

export function carrito_agregar(_id) {
  return {
    type: CARRITO_ADD,
    _id
  }
}

export function carrito_quitar(_id) {
  return {
    type: CARRITO_REMOVE,
    _id
  }
}

export function carrito_eliminar(_id) {
  return {
    type: CARRITO_DELETE,
    _id
  }
}

function fijar_sucursales(res) {
  const data = res.data
  if (isSuccess(res)) {
    return {
      type: FIJAR_SUCURSALES,
      sucursales: data.sort(sortSucursales)
    }
  }
  return fijar_error(data)
}

function sortSucursales(a, b) {
  return a.id - b.id
}

function fijar_comunas(res) {
  const data = res.data
  if (isSuccess(res)) {
    return {
      type: FIJAR_COMUNAS,
      comunas: data
    }
  }
  return fijar_error(data)
}

function fijar_session(res) {
  const data = res.data
  if (isSuccess(res)) {
    return {
      type: FIJAR_USUARIO,
      usuario: data
    }
  }
  return fijar_error(data)
}

function fijar_error(e) {
  console.error(e)
  return {
    type: FIJAR_ERROR,
    message: e.message ? e.message : e
  }
}

function eliminar_session() {
  return {
    type: ELIMINAR_SESSION
  }
}

function buscar_sucursal(sucursales, id_comuna) {
  return sucursales.find(v => v.comunas.some(k => k === id_comuna))
}