import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { SELECCIONAR_COMUNA } from '../utils/Routes'

import './ComunaActual.css'
import { SettingIcon } from './Icons'

const ComunaActual = ({ nombre }) => {
  return (
    <div className="cs-comuna">
      <Link to={SELECCIONAR_COMUNA}>
        <h3>Sucursal {nombre}</h3>
      </Link>
      <small>Presiona para cambiar el sector</small>
    </div>
  )
}

const mapStateToProps =({ comunas, session }) => {
  const comuna = comunas.find(v => v.id === session.id_comuna)
  return {
    nombre: comuna ? comuna.nombre : '----------'
  }
}

export default connect(mapStateToProps)(ComunaActual)