import React, {  Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from "moment";

import { crear_session, google_auth } from "../actions";
import { estadoPedidoWebpay, isSuccess } from '../api';
import { autorizado_from_state } from '../reducers';
import { fecha } from '../utils';
import { clp } from '../utils/Numeral';
import { Link } from 'react-router-dom';
import { PEDIDO } from '../utils/Routes';


class Comprobante extends Component {
  state = {
    loading: true,
    uuid: '',
    momento: '',
    buy_order: '',
    session_id: '',
    authorization_code: '',
    monto: 0
  }

  async componentDidMount() {
    try {
      const uuid = this.props.match.params.uuid
      const res = await estadoPedidoWebpay(uuid)
      console.log('comprobante', res)
      if (isSuccess(res)) {
        const data = res.data
        this.setState({ 
          uuid,
          momento: data.momento,
          monto: data.monto,
          buy_order: data.buy_order,
          session_id: data.session_id,
          authorization_code: data.authorization_code,
        })
      }
    } catch (e) {
      console.error('comprobante transaccion',  e)
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    const { uuid, momento, monto, buy_order, session_id, authorization_code } = this.state

    return (
      <div>
        <section className="news-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-md-6 col-md-offset-3">
                <div className="panel panel-default">
                  <div className="panel-body">
                    <div className="sec-title-one">
                      <h2>Kamisushi</h2>
                      <h4>Comprobante</h4>
                      <p>Hemos ingresado tu pedido!</p>
                    </div>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Número de orden</td>
                          <td>{buy_order}</td>
                        </tr>
                        <tr>
                          <td>Número de sesion</td>
                          <td>{session_id}</td>
                        </tr>
                        <tr>
                          <td>Código de autorización</td>
                          <td>{authorization_code}</td>
                        </tr>
                        <tr>
                          <td>Codigo del pedido</td>
                          <td>{uuid}</td>
                        </tr>
                        <tr>
                          <td>Fecha y hora</td>
                          <td>{fecha(momento)}</td>
                        </tr>
                        <tr>
                          <td>Medio de pago</td>
                          <td>Webpay</td>
                        </tr>
                        <tr>
                          <td>Monto</td>
                          <td>${clp(monto)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Link to={PEDIDO}>
                      <button className="theme-btn btn-style-three proceed-btn btn-block">Ver pedido</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  autorizado: autorizado_from_state(state)
})

const mapDispatachToProps = (dispatch)  => bindActionCreators({
  crear_session,
  google_auth,
}, dispatch)

export default connect(mapStateToProps, mapDispatachToProps)(Comprobante)