import React from 'react'

const style = {
  position: 'fixed',
  bottom: '16px',
  width: '100%',
  zIndex: 999,
  display: 'flex',
  justifyContent: 'center'
}

const boxStyle = {
  backgroundColor: '#f44336',
  padding: '12px 18px',
  color: 'white',
  textAlign: 'center',
  borderRadius: '8px'
}

const Mensaje = ({ visible }) => {
  if (!visible) {
    return null
  }

  return (
    <div style={style}>
      <div className="fade-in" style={boxStyle}>
        Producto agregado
      </div>
    </div>
  )
}

export default Mensaje