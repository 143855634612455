import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import CartItem from '../components/CartItem'
import { clp } from '../utils/Numeral'
import { CHECKOUT, LOGIN, PEDIDO } from '../utils/Routes'
import { reduceProductos, resetScroll } from "../utils";
import { carrito_agregar, carrito_eliminar, carrito_quitar } from "../actions";
import { carrito_from_state, existe_pedido, productos_from_state } from "../reducers";


function sortProducto(a, b) {
  return a.index - b.index
}

const ShoppingCart = ({ carrito, carrito_agregar, carrito_eliminar, carrito_quitar, existe_pedido, history }) => {
  useEffect(() => resetScroll(), [])
  const total = carrito.reduce(reduceProductos, 0)

  if (existe_pedido) {
    return <Redirect to={PEDIDO} />
  }

  const continuar = () => history.push(CHECKOUT)
  const volver = () => history.goBack()

  return (
    <section className="cart-section">
      <div className="auto-container">
        <div className="row">
          <div className="col-md-9 col-md-offset-2">
            <div className="cart-options clearfix">
              <div className="clearfix">
                {carrito.sort(sortProducto).map((v, index) => (
                  <CartItem key={v._id} {...v}
                    underline={index !== carrito.length - 1}
                    carrito_agregar={carrito_agregar}
                    carrito_eliminar={carrito_eliminar}
                    carrito_quitar={carrito_quitar} />
                ))}
              </div>
            </div>
            <div className="row clearfix">
              <div className="column pull-right col-md-8 col-sm-12 col-xs-12">
                <div className="price-calculator">
                  <div className="outer-box">
                    <div className="top-bar">
                      <div className="default-title"><h3>Total del carrito</h3></div>
                      <ul className="totals-table">
                        <li className="clearfix"><span className="col bold-text">Subtotal</span><span className="col total-amount">${clp(total)}</span></li>
                      </ul>
                    </div>
                  </div>
                  <div className="check-btns">
                    <button className="theme-btn btn-style-two update-btn" type="button" onClick={volver}>Volver</button>
                    <button onClick={continuar} type="button" className="theme-btn btn-style-three proceed-btn" disabled={carrito.length === 0}>Continuar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = (state) =>  ({
  existe_pedido: existe_pedido(state),
  carrito: carrito_from_state(state)
})

const mapDispatchToProps =  (dispatch) => bindActionCreators({
  carrito_agregar, carrito_eliminar, carrito_quitar
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)