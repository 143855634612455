import React, {  Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { enviarCorreo, isSuccess } from '../api';
import { ErrorMessage } from '../components/Alerts';
import { int, is_email, is_empty, resetScroll } from '../utils';
import { crear_session, google_auth } from "../actions";
import { ROOT } from "../utils/Routes";
import { autorizado_from_state } from '../reducers';
import { Redirect } from 'react-router-dom';
import { GLogin } from '../components/GoogleButtons';



class Login extends Component {
  state =  {
    step: 0,
    email: '',
    codigo: '',
    error: '',
    loading: false
  }

  componentDidMount() {
    resetScroll()
  }

  enviarCorreo = async (e) => {
    e.preventDefault()
    this.toggleLoading()
    this.clearError()
    try {
      const email = this.state.email.trim().toLowerCase()
      const res = await enviarCorreo(email)

      if (isSuccess(res))  {
        this.codeStep()
      } else {
        this.error(res.data)
      }
      console.log(res)
    } catch (e) {
      this.error(e)
    } finally {
      this.toggleLoading()
    }
  }

  login = async (e) => {
    e.preventDefault()
    this.toggleLoading()
    this.clearError()
    try {
      const email = this.state.email.trim().toLowerCase()
      const codigo = int(this.state.codigo)
      const res = await this.props.crear_session(email, codigo)

      console.log(res)
      if (!isSuccess(res)) {
        this.error(res.data)
      }
    } catch (e) {
      this.error(e)
    } finally {
      this.toggleLoading()
    }
  }

  onGoogleSuccess = async (res) => {
    await this.props.google_auth(res)
  }

  onGoogleFail = (res) => {
    console.log('fail', res)
    this.error('Fallo login google')
  }

  error = (e) => {
    console.error(e)
    this.setState({ error: e.message ? e.message : e })
  }

  clearError = () => this.setState({ error: '' })

  onChange = (e) => this.setState({ [e.target.id]: e.target.value })
  codeStep = (v) => this.setState({ step: 1 })
  emailStep = (v) => this.setState({ step: 0 })
  toggleLoading = () => this.setState({ loading: !this.state.loading })

  render() {
    const {  email, codigo, step, error, loading } = this.state
    const emailStep = step === 0
    const disabledEmail = !is_email(email) || is_empty(email)
    const disabledCodigo = is_empty(codigo) || isNaN(codigo)
    const disabled = emailStep ? disabledEmail : disabledCodigo

    if (this.props.autorizado) {
      return <Redirect to={ROOT} />
    }

    return (
      <div>
        {/*<section className="page-title-alt" style={{backgroundImage: 'url(/images/background/sup.png)'}}>
        </section>*/}
        <section className="news-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-md-6 col-md-offset-3">
                <div className="panel panel-default">
                  <div className="panel-body">
                    <div className="sec-title-one">
                      <h2>Kamisushi</h2>
                      <h4>Ingresar</h4>
                        <p>
                          Puedes ingresar tu correo y te enviaremos un codigo con el cual puedes
                          ingresar para hacer tu pedido, también puedes usar el botón google para
                          ingresar
                        </p>
                    </div>
                    <div className="content">
                      <div className="text">
                      </div>
                    </div>
                    <div className="form-box default-form wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                      <form onSubmit={emailStep ? this.enviarCorreo : this.login}>
                        <div className="clearfix">
                          <div className="form-group icon-group">
                            {emailStep ?
                              <EmailForm email={email} onChange={this.onChange} /> :
                              <CodigoForm codigo={codigo} email={email} onChange={this.onChange} />}
                          </div>
                        </div>
                        <ErrorMessage value={error} />
                        <button className="theme-btn btn-style-three proceed-btn btn-block" disabled={disabled || loading}>{loading ? '...' : 'Continuar'}</button>
                        {emailStep && <GLogin onFailure={this.onGoogleFail} onSuccess={this.onGoogleSuccess} />}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const EmailForm = ({ email, onChange }) => (
  <div className="group-inner">
    <label>Correo electronico</label>
    <input id="email" value={email} type="text" placeholder="correo@electronico.com" onChange={onChange} />
  </div>
)

const CodigoForm = ({ email, codigo, onChange }) => (
  <div className="group-inner">
    <label>Ingresa el codigo</label>
    <input id="codigo" value={codigo} type="text" onChange={onChange} />
    <p>Código enviado a {email}</p>
  </div>
)

const mapStateToProps = (state) => ({
  autorizado: autorizado_from_state(state)
})

const mapDispatachToProps = (dispatch)  => bindActionCreators({
  crear_session,
  google_auth,
}, dispatch)

export default connect(mapStateToProps, mapDispatachToProps)(Login)