export const FIJAR_SESSION = 'FIJAR_SESSION'
export const FIJAR_G_SESSION = 'FIJAR_G_SESSION'
export const ELIMINAR_SESSION = 'ELIMINAR_SESSION'
export const FIJAR_USUARIO = 'FIJAR_USUARIO'
export const FIJAR_DIRECCIONES = 'FIJAR_DIRECCIONES'

export const FIJAR_PRODUCTOS = 'FIJAR_PRODUCTOS'
export const CARRITO_ADD = 'CARRITO_ADD'
export const CARRITO_REMOVE = 'CARRITO_REMOVE'
export const CARRITO_DELETE = 'CARRITO_DELETE'

export const FIJAR_SUCURSALES = 'FIJAR_SUCURSALES'
export const FIJAR_COMUNAS = 'FIJAR_COMUNAS'
export const FIJAR_COMUNA_ACTIVA = 'FIJAR_COMUNA_ACTIVA'

export const FIJAR_ERROR = 'FIJAR_ERROR'

export const FIJAR_PEDIDO = 'FIJAR_PEDIDO'
export const QUITAR_PEDIDO = 'QUITAR_PEDIDO'