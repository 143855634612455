import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import AutoComplete from "react-google-autocomplete";

import CartItem from '../components/CartItem'
import { autorizado_from_state, carrito_from_state, comuna_from_state, existe_pedido, persona_from_state, sucursal_from_state } from '../reducers'
import { DESPACHO, EFECTIVO, RETIRO, TARJETA } from '../utils/Constants'
import { LOGIN, PEDIDO, SELECCIONAR_COMUNA } from '../utils/Routes'
import { enviar_pedido, enviar_pedido_webpay } from "../actions";
import { is_empty, reduceProductos, resetScroll } from '../utils'
import { costoDespacho, isSuccess } from '../api'
import { googleKey } from "../config";
import { clp } from '../utils/Numeral';
import { SucursalHorizontal } from '../components/Sucursal';
import { ErrorMessage } from '../components/Mensajes';
import Spinner from '../components/Spinner';



function sortProducto(a, b) {
  return a.index - b.index
}

class Checkout extends Component {
  state = {
    nombre: '',
    telefono: '',
    calle: '',
    coordenadas: null,
    depto: '',
    comentario: '',
    envio: DESPACHO,
    pago: EFECTIVO,
    loading: false,
    costo_despacho: 0,
    calculando: false,
    id_direccion: null,
    error: ''
  }

  componentDidMount() {
    resetScroll()
    const { nombre, telefono, direcciones } = this.props.persona

    this.setState({
      nombre,
      telefono,
    })

    if (direcciones.length > 0) {
      const direccion = direcciones[0]
      //console.log('direccion', direccion)

      if (direccion.coordenadas) {
        this.setState({
          id_direccion: direccion.id,
          calle: direccion.calle,
          depto: direccion.depto,
          coordenadas: direccion.coordenadas
        })
        this.calcularDespacho(direccion.coordenadas)
      }
    }
  }

  calcularDespacho = async (coordenadas) => {
    const body = {
      destination: `${coordenadas.latitude},${coordenadas.longitude}`,
      sucursal: this.props.sucursal.id
    }

    try {
      this.toggleCalcular()
      const res = await costoDespacho(body)
      //console.log(res)
      if (isSuccess(res)) {
        this.setState({
          costo_despacho: res.data
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.toggleCalcular()
    }
  }

  onPlaceSelected = async (place) => {
    //console.log(place)
    const coordenadas = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    }
    this.setState({ coordenadas, calle: place.formatted_address, id_direccion: null })

    await this.calcularDespacho(coordenadas)
  }

  onChange = ({ target }) => this.setState({ [target.id]: target.value })
  toggle = () => this.setState({ loading: !this.state.loading })
  toggleCalcular = () => this.setState({ calculando: !this.state.calculando })

  direccionChange = (e) => {
    this.setState({
      id_direccion: null,
      coordenadas: null,
      costo_despacho: 0,
      calle: e.target.value
    })
  }

  selectDireccion = (id) => {
    const direccion = this.props.persona.direcciones.find(v => v.id === id)

    this.setState({
      id_direccion: direccion.id,
      calle: direccion.calle,
      costo_despacho: 0
    })
    this.calcularDespacho(direccion.coordenadas)
  }

  submit = async (e) => {
    e.preventDefault()
    this.toggle()

    try {
      const { nombre, telefono, calle, depto, comentario, envio, pago, coordenadas, costo_despacho, id_direccion } = this.state
      const { carrito, comuna, persona } = this.props

      const direccion = {
        id: id_direccion,
        calle, depto, coordenadas
      }

      let address = calle
      if (!is_empty(depto)) {
        address = `${calle}, Depto: ${depto}`
      }

      const pedido = {
        user: {
          email: persona.email,
          name: nombre.trim(),
          phone: telefono.trim(),
          address,
          id_comuna: comuna.id,
          id_direccion,
          coordenadas
        },
        products: carrito.map(v => ({ _id: v._id, quantity: v.cantidad })),
        comment: comentario.trim(),
        tipoPago: pago,
        type: envio,
        deliveryCost: envio === DESPACHO ? costo_despacho : 0
      }
      
      if (pago === TARJETA) {
        const resWp = await this.props.enviar_pedido_webpay(pedido, direccion)
        //console.log('webpay', resWp)
        if (isSuccess(resWp)) {
          this.webpayHiddenForm(resWp.data.url, resWp.data.token)
        } else {
          this.error(resWp)
          this.toggle()
        }
      } else {
        const res = await this.props.enviar_pedido(pedido, direccion)
        //console.log('pedido', res)
        if (!isSuccess(res)) {
          this.error(res)
        }
        this.toggle()
      }
    } catch (e) {
      console.error('enviar_pedido', e)
      this.error(e)
      this.toggle()
    }
  }

  webpayHiddenForm = (url, ws_token) => {
    //console.log('form', url, ws_token)
    var form = document.createElement("form");
    form.action = url;
    form.method = 'post';
    //form.target = "blank";
    var input = document.createElement("input");
    input.name = "token_ws";
    input.value = ws_token
    form.appendChild(input);
    form.style.display = 'none';
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  error = (e) => {
    if (e.data === 'No existe jornada activa') {
      this.setState({ error: 'El horario de operacion a terminado' })
    } if (e.data === 701) {
      this.setState({ error: 'No podemos recibir mas pedidos, intenta mas tarde' })
    } else {
      this.setState({ error: 'No hemos podido ingresar tu pedido, intenta mas tarde'})
    }
  }

  back = () => this.props.history.goBack()

  render() {
    const { carrito, comuna, autorizado, persona, existe_pedido, sucursal } = this.props
    const { nombre, telefono, calle, depto, envio, pago, comentario, costo_despacho, id_direccion, error, loading } = this.state

    if (!autorizado) {
      return <Redirect to={LOGIN} />
    }

    if (!comuna) {
      return <Redirect to={SELECCIONAR_COMUNA} />
    }

    if (existe_pedido) {
      return <Redirect to={PEDIDO} />
    }

    const despacho = envio === DESPACHO
    const disabled = is_empty(nombre) || is_empty(telefono) || (despacho && is_empty(calle)) || this.state.calculando || (despacho && costo_despacho === 0) || loading
    const total_productos = carrito.reduce(reduceProductos, 0)
    const total = total_productos + (despacho ? costo_despacho : 0)

    /**
     * <div className="shop-form default-form">
     * se quita default-form por bug no se puede escribir en el input
     */
    return (
      <div className="checkout-page">
        <div className="auto-container">
          <section className="cart-section">
            <form onSubmit={this.submit} className="cart-options clearfix">
              <div className="billing-details">
                <div className="shop-form">
                    <div className="row clearfix">
                      <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <div className="place-order">
                          <div className="default-title"><h2>Metodo de envio</h2></div>
                          <div className="payment-options">
                            <ul>
                              <li>
                                <div className="radio-option">
                                  <RadioButton id={RETIRO} name="envio" value={RETIRO} checked={envio === RETIRO} onChange={this.onChange} />
                                  <label htmlFor={RETIRO}>Retiro en local</label>
                                </div>
                              </li>
                              <li>
                                <div className="radio-option">
                                  <RadioButton id={DESPACHO} name="envio" value={DESPACHO} checked={envio === DESPACHO} onChange={this.onChange} />
                                  <label htmlFor={DESPACHO}>Despacho a domicilio</label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="default-title"><h2>Ingresa tus datos</h2></div>
                        <div className="row clearfix">
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <input type="text" id="nombre" value={nombre} placeholder="Nombre" onChange={this.onChange} />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <input type="number" id="telefono" value={telefono} placeholder="Telefono" onChange={this.onChange}/>
                          </div>
                          {despacho && <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <AutoComplete
                              placeholder="Ingresa tu dirección"
                              className="cs-g-autocomplete"
                              apiKey={googleKey}
                              onPlaceSelected={this.onPlaceSelected}
                              types={['address']}
                              componentRestrictions={{ country: "cl" }}
                              value={calle}
                              onChange={this.direccionChange}
                            />
                          </div>}
                          {despacho && <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <input type="text" id="depto" value={depto} placeholder="Depto / Casa (opcional)" onChange={this.onChange} />
                          </div>}
                          {despacho && <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <input value={"Costo despacho $" + clp(costo_despacho)} type="text" disabled/>
                          </div>}
                          <div className="form-group">
                            <textarea id="comentario" value={comentario} placeholder="Comentario" onChange={this.onChange} />
                          </div>
                        </div>
                      </div>
                      {despacho && <Direcciones data={persona.direcciones} selected={id_direccion} onClick={this.selectDireccion} />}
                    </div>                             
                </div>
              </div>
              <SucursalHorizontal { ...sucursal} />
              <div className="mt-3 clearfix cart-options">
                {carrito.sort(sortProducto).map((v, index) => (
                  <CartItem key={v._id} {...v}
                    underline={index !== carrito.length - 1} />
                ))}
              </div>
              <div className="mt-3 place-order">
                <div className="default-title"><h2>Metodo de pago</h2></div>
                <div className="payment-options">
                  <ul>
                    <li>
                      <div className="radio-option">
                        <RadioButton id={EFECTIVO} name="pago" value={EFECTIVO} checked={pago === EFECTIVO} onChange={this.onChange} />
                        <label htmlFor={EFECTIVO}>Efectivo</label>
                      </div>
                    </li>
                    {sucursal.webpay && <li>
                      <div className="radio-option">
                        <RadioButton id={TARJETA} name="pago" value={TARJETA} checked={pago === TARJETA} onChange={this.onChange} />
                        <label htmlFor={TARJETA}>Webpay *Seras redirigido al sitio de webpay</label>
                      </div>
                    </li>}
                  </ul>
                </div>
                
              </div>
              <div className="column pull-right col-md-8 col-sm-12 col-xs-12">
                <div className="price-calculator">
                  <div className="outer-box">
                    <div className="top-bar">
                      <div className="default-title"><h3>Total del carrito</h3></div>
                      <ul className="totals-table">
                        <li className="clearfix"><span className="col bold-text">Subtotal</span><span className="col total-amount">${clp(total_productos)}</span></li>
                        {despacho && <li className="clearfix"><span className="col bold-text">Despacho</span><span className="col total-amount">${clp(costo_despacho)}</span></li>}
                      </ul>
                      <ul className="lower-total">
                        <li className="clearfix"><span className="col bold-text">TOTAL</span><span className="col total">${clp(total)}</span></li>
                      </ul>
                    </div>
                  <ErrorMessage message={error} />
                  </div>
                  <div className="check-btns">
                    <button className="theme-btn btn-style-two update-btn" type="button" onClick={this.back}>Volver</button>
                    <button className="theme-btn btn-style-three proceed-btn" disabled={disabled}>
                      {loading ? <Spinner /> : 'Enviar Pedido'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    )
  }
}

const Direcciones = ({ data, onClick, selected }) => data.length > 0 && (
  <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
    <div className="default-title"><h2>Enviar a otra dirección?</h2></div>
    <div className="clearfix">
      <div className="payment-options">
        <ul>
          {data.map(v => (
            <li key={v.id}>
              <div className="radio-option">
                <input type="radio" name="address-group" id={v.id} onChange={() => onClick(v.id)} checked={selected === v.id} />
                <label htmlFor={v.id}>{v.calle}</label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)

const RadioButton = ({ id, name, checked, onChange, value }) => {
  const _onChange = () => {
    onChange({ target: { id: name, value } })
  }

  return (
    <input type="radio" name={name} id={id} checked={checked} value={value} onChange={_onChange} />
  )
}

const mapStateToProps = (state) => ({
  existe_pedido: existe_pedido(state),
  autorizado: autorizado_from_state(state),
  carrito: carrito_from_state(state),
  comuna: comuna_from_state(state),
  sucursal: sucursal_from_state(state),
  persona: persona_from_state(state)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ enviar_pedido, enviar_pedido_webpay }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)