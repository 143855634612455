import React from "react";
import { connect } from "react-redux";
import { carrito_from_state } from "../reducers";
import { ShoppingIcon } from "./Icons"

const style = {
  display: 'flex',
  padding: '8px 16px'
}

const CartButton = ({ cantidad, onClick }) => (
  <button type="button" className="theme-btn" style={style} onClick={onClick}>
    <ShoppingIcon />
    <div style={{ marginLeft: '4px'}}>{cantidad}</div>
  </button>
)


const mapStateToProps = (state) => ({
  cantidad: carrito_from_state(state).reduce((t, v) => t + v.cantidad, 0),
})

export default connect(mapStateToProps)(CartButton)