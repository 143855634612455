import { store } from "../Store";
import { host } from "../config";


export function get(path) {
  return request(path, 'GET')
}

export function post(path, body) {
  return request(path, 'POST', body)
}

export function del(path) {
  return request(path, 'DELETE')
}

export function put(path, body) {
  return request(path, 'PUT', body)
}

function request(path, method, body) {
  const { session } = store.getState()

  return fetch(host + path, {
    method,
    headers: {
      'API-TOKEN': session.token,
      'TOKEN-TYPE': session.type
    },
    body: body ? JSON.stringify(body) : undefined
  }).then(res => res.json())//.then(lag)
}

function lag(v) {
  return new Promise(res => {
    setTimeout(() => res(v), 1000)
  })
}
